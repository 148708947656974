import { useCallback, useEffect, useState } from 'react'

import {
  AisSource,
  NavStatusCode,
  VesselFilter,
  aisShipTypes,
  filterSpeedRangeMax,
} from '@griegconnect/krakentools-kmap'
import { Close as CloseIcon, Filter as FilterIcon } from '@griegconnect/krakentools-react-icons'
import { Badge, Checkbox, FormControlLabel, FormGroup, Slider, styled } from '@mui/material'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import FormControl from '@mui/material/FormControl'
import FormLabel from '@mui/material/FormLabel'
import IconButton from '@mui/material/IconButton'
import Typography from '@mui/material/Typography'
import { useQuery } from '@tanstack/react-query'
import { useRecoilState } from 'recoil'
import { MapTranslucentPaper } from '../shared/MapTranslucentPaper'
import { useMapContext } from '../MapContext'
import { MonitorApi } from '../apis/MonitorApi'
import { vesselFilterAtom } from '../atoms/liveViewAtoms'
import { MapButtonPanelRegion } from '../shared/MapButtonPanel'
import { MapPopperButton } from '../shared/MapPopperButton'
import { aisNavStatuses } from '../utils/aisNavStatuses'

export const QUERY_KEY_VESSEL_LISTS = 'vessel-lists'

const StyledPaper = styled(MapTranslucentPaper)(({ theme }) => ({
  paddingTop: theme.spacing(2),
  paddingLeft: theme.spacing(2),
  paddingRight: theme.spacing(1),
  paddingBottom: theme.spacing(4),
  zIndex: theme.zIndex.modal,
  width: 420,
  display: 'flex',
  flexDirection: 'column',
  maxHeight: 'calc(80vh - 64px)',
}))

const SmallButton = styled(Button)(({ theme }) => ({
  textTransform: 'none',
  paddingLeft: 0,
}))

export type VesselFilterControlProps = {
  region?: MapButtonPanelRegion
  monitorApi: MonitorApi
  expectedMmsis?: number[]
  usingPointsLayer?: boolean
}

export const VesselFilterControl: React.FC<VesselFilterControlProps> = ({
  region,
  monitorApi,
  expectedMmsis,
  usingPointsLayer,
}) => {
  const [open, setOpen] = useState(false)
  const [expandedStatus, setExpandedStatus] = useState(false)
  const [expandedShipTypes, setExpandedShipTypes] = useState(false)
  const [selectedVesselListIds, setSelectedVesselListIds] = useState<string[]>([])
  const { kmapInstance: instance } = useMapContext()
  const [filter, setFilter] = useRecoilState(vesselFilterAtom(instance.mapElementId))
  const [isFilterActive, setIsFilterActive] = useState(false)

  const [sources, setSources] = useState<AisSource[]>(filter.source ? [filter.source] : [])
  const [onlyScheduled, setOnlyScheduled] = useState(false)

  const { data: vesselLists } = useQuery([QUERY_KEY_VESSEL_LISTS], async () => monitorApi.vesselLists(false, true))

  const aisShipTypesArray = Array.from(aisShipTypes)
  const filterShipTypes: VesselFilter['shipType'] = {}
  aisShipTypesArray.forEach((type1) => {
    filterShipTypes[type1[1]] = [type1[0]]
    aisShipTypesArray.forEach((type2) => {
      if (type1[1] === type2[1] && type1 !== type2) filterShipTypes[type1[1]].push(type2[0])
    })
  })
  const getFilteredMsis = useCallback(
    (vesselListIds: string[]) => {
      const portCallMmsis = onlyScheduled ? expectedMmsis || [] : []
      const ids =
        vesselListIds && vesselLists
          ? [
              ...vesselLists!
                .filter((vesselList) => vesselListIds.includes(vesselList.id))
                .flatMap((x) => x.list.map((mmsi) => mmsi.id)),
              ...portCallMmsis,
            ]
          : portCallMmsis
      return ids.length === 0 ? undefined : ids
    },
    [vesselLists, expectedMmsis, onlyScheduled]
  )

  const handleToggleOnlyScheduled = () => {
    setOnlyScheduled((prev) => !prev)
  }

  const handleToggle = () => {
    setOpen(!open)
  }

  const handleClearFilter = () => {
    setSelectedVesselListIds([])
    setFilter({})
  }

  const handleVesselListChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const vesselListId = (event.target as HTMLInputElement).value
    const action: 'add' | 'remove' = selectedVesselListIds.includes(vesselListId) ? 'remove' : 'add'
    let newVesselListsSelected: string[] = []

    if (action === 'add') {
      newVesselListsSelected = [...selectedVesselListIds, vesselListId]
    } else {
      newVesselListsSelected = selectedVesselListIds.filter((vesselList) => vesselList !== vesselListId)
    }

    setSelectedVesselListIds(newVesselListsSelected)

    setFilter((currentFilter) => ({
      ...currentFilter,
      mmsis: getFilteredMsis(newVesselListsSelected),
    }))
  }

  useEffect(() => {
    setFilter((currentFilter) => ({
      ...currentFilter,
      mmsis: getFilteredMsis(selectedVesselListIds),
    }))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [onlyScheduled, expectedMmsis, selectedVesselListIds, getFilteredMsis])

  useEffect(() => {
    setIsFilterActive(
      !Object.keys(filter).some((key) =>
        Object.keys((filter[key as keyof VesselFilter] as Record<string, any>) || {}).some(
          (fk) => (filter[key as keyof VesselFilter] as Record<string, any>)[fk]
        )
      )
    )
  }, [filter])

  const renderFilterContent = () => (
    <StyledPaper styleVariant="static">
      <Box
        display="flex"
        flexDirection="row"
        justifyContent="space-between"
        alignItems="center"
        sx={{ paddingBottom: (theme) => theme.spacing(2) }}
      >
        <Typography variant="h6" sx={{ m: 1 }}>
          Filter
        </Typography>

        <Box>
          <Button variant="text" color="primary" onClick={handleClearFilter}>
            Clear
          </Button>
          <IconButton
            onClick={() => setOpen(false)}
            sx={{ padding: (theme) => theme.spacing(0.5), marginRight: (theme) => theme.spacing(0.5) }}
            size="large"
          >
            <CloseIcon />
          </IconButton>
        </Box>
      </Box>
      {renderContent()}
    </StyledPaper>
  )

  const renderContent = () => (
    <Box display="flex" flexDirection="column" overflow="auto">
      {!usingPointsLayer && (
        <>
          <FormControl sx={{ m: 1 }} component="fieldset" variant="standard">
            <FormLabel component="legend" sx={{ marginBottom: 1 }}>
              Ship type
            </FormLabel>
            <FormGroup>
              {Object.keys(filterShipTypes)
                .sort((a, b) => (a > b ? 1 : -1))
                .slice(0, expandedShipTypes ? undefined : 3)
                .map((typename: keyof typeof filterShipTypes) => {
                  return (
                    <FormControlLabel
                      key={typename}
                      control={
                        <Checkbox
                          checked={Object.keys(filter.shipType || {}).includes(String(typename)) || false}
                          onChange={(evt) => {
                            const chosenShipTypes = { ...filter.shipType }
                            if (evt.target.checked) {
                              chosenShipTypes[typename] = filterShipTypes[typename]
                            } else {
                              delete chosenShipTypes[typename]
                            }
                            setFilter({
                              ...filter,
                              shipType: chosenShipTypes,
                            })
                          }}
                        />
                      }
                      label={typename}
                    />
                  )
                })}
            </FormGroup>

            <Box>
              <SmallButton
                size="small"
                onClick={() => {
                  setExpandedShipTypes((expanded) => !expanded)
                }}
              >
                Show {expandedShipTypes ? 'less' : 'more'}
              </SmallButton>
            </Box>
          </FormControl>
        </>
      )}
      <FormControl sx={{ m: 1 }} component="fieldset" variant="standard">
        <FormLabel component="legend" sx={{ marginBottom: 1 }}>
          Status
        </FormLabel>
        <FormGroup>
          {Array.from(aisNavStatuses)
            .slice(0, expandedStatus ? undefined : 3)
            .sort((a, b) => (a[1].displayName > b[1].displayName ? 1 : -1))
            .map((navStatus) => (
              <FormControlLabel
                key={navStatus[0]}
                control={
                  <Checkbox
                    checked={filter.status?.[navStatus[0] as NavStatusCode] || false}
                    onChange={(evt) =>
                      setFilter({ ...filter, status: { ...filter.status, [navStatus[0]]: evt.target.checked } })
                    }
                  />
                }
                label={navStatus[1].displayName}
              />
            ))}
        </FormGroup>
        <Box>
          <SmallButton
            size="small"
            onClick={() => {
              setExpandedStatus((expanded) => !expanded)
            }}
          >
            Show {expandedStatus ? 'less' : 'more'}
          </SmallButton>
        </Box>
      </FormControl>
      {!usingPointsLayer && (
        <>
          <FormControl sx={{ m: 1 }} component="fieldset" variant="standard">
            <FormLabel component="legend" sx={{ marginBottom: 1 }}>
              AIS class
            </FormLabel>
            <FormGroup>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={filter.aisClass?.classA || false}
                    onChange={(evt) =>
                      setFilter({ ...filter, aisClass: { ...filter.aisClass, classA: evt.target.checked } })
                    }
                  />
                }
                label="Class A"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={filter.aisClass?.classB || false}
                    onChange={(evt) =>
                      setFilter({ ...filter, aisClass: { ...filter.aisClass, classB: evt.target.checked } })
                    }
                  />
                }
                label="Class B"
              />
            </FormGroup>
          </FormControl>
        </>
      )}
      <FormControl sx={{ m: 1 }} component="fieldset" variant="standard">
        <FormLabel component="legend" sx={{ marginBottom: 1 }}>
          Speed
        </FormLabel>
        <Box display="flex" flexDirection="column" sx={{ paddingLeft: 2, paddingRight: 2, marginRight: 1 }}>
          <Slider
            value={filter.speedRange || [0, filterSpeedRangeMax]}
            size="small"
            onChange={(_evt, newSpeed) =>
              Array.isArray(newSpeed) &&
              setFilter({
                ...filter,
                speedRange: newSpeed[0] === 0 && newSpeed[1] === filterSpeedRangeMax ? undefined : newSpeed,
              })
            }
            aria-labelledby="speed-slider"
            min={0}
            max={filterSpeedRangeMax}
            valueLabelDisplay={'on'}
            valueLabelFormat={(val) => val + (val === filterSpeedRangeMax ? '+ kn' : ' kn')}
            sx={{ marginTop: 3 }}
            disabled={filter.speed?.onlyMoving}
          />
          <FormControlLabel
            sx={{ marginLeft: -3 }}
            control={
              <Checkbox
                checked={filter.speed?.onlyMoving || false}
                onChange={(evt) => setFilter({ ...filter, speed: { ...filter.speed, onlyMoving: evt.target.checked } })}
              />
            }
            label="Underway"
          />
        </Box>
      </FormControl>
      <FormControl sx={{ m: 1 }} component="fieldset" variant="standard">
        <FormLabel id="vessel-list-radio-buttons-group-label" component="legend" sx={{ marginBottom: 1 }}>
          Vessel list
        </FormLabel>

        {vesselLists && (
          <FormGroup onChange={handleVesselListChange}>
            {vesselLists.map((vesselList) => (
              <FormControlLabel
                key={vesselList.id}
                value={vesselList.id}
                label={`${vesselList.name} (${vesselList.list?.length})`}
                control={<Checkbox checked={selectedVesselListIds.includes(vesselList.id) ? true : false} />}
              />
            ))}
          </FormGroup>
        )}
        {expectedMmsis && (
          <FormGroup onChange={handleToggleOnlyScheduled}>
            <FormControlLabel
              value={onlyScheduled}
              label={'Expected port calls'}
              control={<Checkbox checked={onlyScheduled} />}
            />
          </FormGroup>
        )}
      </FormControl>
      {!usingPointsLayer && (
        <>
          <FormControl sx={{ m: 1 }} component="fieldset" variant="standard">
            <FormLabel component="legend" sx={{ marginBottom: 1 }}>
              AIS Sources
            </FormLabel>
            <FormGroup>
              {(['ais', 'sat'] as AisSource[]).map((source) => (
                <FormControlLabel
                  key={source}
                  control={
                    <Checkbox
                      checked={sources.includes(source)}
                      onChange={(evt) => {
                        const newSources = evt.target.checked
                          ? [...sources, source]
                          : sources.filter((s) => s !== source)
                        setSources(newSources)
                        if (newSources.length === 1) {
                          setFilter({ ...filter, source: newSources[0] })
                        } else {
                          const newFilter = { ...filter }
                          delete newFilter.source
                          setFilter(newFilter)
                        }
                      }}
                    />
                  }
                  label={source === 'ais' ? 'Terrestrial' : 'Satellite'}
                />
              ))}
            </FormGroup>
          </FormControl>
        </>
      )}
    </Box>
  )

  return (
    <MapPopperButton
      icon={
        <Badge variant="dot" color="primary" invisible={isFilterActive}>
          <FilterIcon />
        </Badge>
      }
      open={open}
      onClose={handleToggle}
      content={renderFilterContent()}
      title="Filter"
      tooltipText="Filter vessels by type, status, speed, vessel list and AIS source."
      region={region}
      mobileContent={
        <Box sx={{ pl: 4 }}>
          <Button
            variant="text"
            color="primary"
            onClick={() => {
              handleClearFilter()
              handleToggle()
            }}
            fullWidth
          >
            Clear filter
          </Button>
          {renderContent()}
        </Box>
      }
    />
  )
}
